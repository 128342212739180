import {
  GET_QR_REPORT_REQUEST,
  GET_QR_REPORT_SUCCESS,
  GET_QR_REPORT_FAILED,
  CLEAR_QR_REPORT,
} from "../../../constants/variables";
import { reactBackendUrl } from "../../../constants/url";
import { Dispatch } from "redux";
import axios from "axios";
import { IQrRequestData } from "../../../types/state/qr";

export const getQrReport = (data: IQrRequestData, token: string | undefined) => (dispatch: Dispatch) => {
  try {
    dispatch({
      type: GET_QR_REPORT_REQUEST,
    });

    const config = {
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }

    const terminalUrl = data.device ? `TerminalId=${data.device}` : '';
    const dateUrl = data.dateFrom ? `start_at=${data.dateFrom}&end_at=${data.dateTo}` : '';

    const response = axios.get(
      `${reactBackendUrl}/qr?${terminalUrl}&${dateUrl}`,
      config);

    response.then((data) => {
      dispatch({
        type: GET_QR_REPORT_SUCCESS,
        payload: data.data.data
      })
    }).catch(() => {
      dispatch({
        type: GET_QR_REPORT_FAILED,
      });
    });

  } catch (err) {
    dispatch({
      type: GET_QR_REPORT_FAILED,
    });
  }
};

export const clearQrReport = () => {
  return {
    type: CLEAR_QR_REPORT
  }
}