import { createSelector } from "reselect";
import { TState } from "../../../types/state";

// местный селектор, чтобы дернуть весь стейт по модалкам
const getModalState = (state: TState) => state.modal;

export const getModalSelector = createSelector(
  getModalState,
  ({ modal }) => modal,
);

export const getModalDataSelector = createSelector(
  getModalState,
  ({ modalData }) => modalData,
);