import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  getTokenEvotorSelector,
  getTokenSelector
} from "../../redux/state/auth/selector";
import { getDevices } from "../../redux/state/devices/action";
import {Select, DatePicker, Space, Checkbox, Tooltip} from 'antd';
import { getDevicesSelector } from "../../redux/state/devices/selector";
import { clearQrReport, getQrReport } from "../../redux/state/report/action";
import TablePaymentList from "../../components/TablePaymentList";
import moment from "moment";
import ReportToExcel from "../../components/ReportToExcel";
import { optionalColumnsConst } from "../../constants/variables";
import './index.scss';

const Report = () => {

  const dispatch = useDispatch();
  const token = useSelector(getTokenSelector);
  const evotorToken = useSelector(getTokenEvotorSelector);
  const devices = useSelector(getDevicesSelector);

  const { Option } = Select;
  const { RangePicker } = DatePicker;

  useEffect(() => {
    if (evotorToken) {
      dispatch(getDevices(evotorToken))
    }
  }, [evotorToken]);

  useEffect(() => {
    dispatch(clearQrReport())
  }, []);

  // ВЫБОР КАССЫ
  const allDevices = devices.length ? [{
    name: 'Все',
    id: '-1'
  },
    ...devices] : [];

  const [chosenDevice, setChosenDevice] = useState<any>(null);

  const onChangeTerminal = (value: any) => {
    setChosenDevice(value);
  };

  // опциональные поля
  const optionalCheckbox = [...optionalColumnsConst];

  const [optionalColumns, setOptionalColumns] = useState<any>([]);

  const toggleOptionalColumns = (checked: boolean, data: any) => {
    setOptionalColumns(checked ?
      [...optionalColumns, data] :
      optionalColumns.filter((item: any) => item.name !== data.name)
    )
  };

  // ВЕСЬ ПЕРИОД
  const [allPeriod, setAllPeriod] = useState<boolean>(false);

  function onChangeAllPeriod (e: any) {
    setChosenDates(null);
    setAllPeriod(e.target.checked);
  }

  // ПЕРИОД
  const [chosenDates, setChosenDates] = useState<any>(null);

  const onChangeDates = (value: any) => {
    setChosenDates(value);
  }

  // ОТПРАВКА ЗАПРОСА НА ОТЧЕТ
  useEffect(() => {
    if (chosenDevice && (allPeriod || chosenDates)) {
      reportRequest()
    }
  }, [chosenDevice, chosenDates, allPeriod]);

  const reportRequest = () => {
    const dates = chosenDates?.length ? chosenDates.map((item: any) => {
      return moment(item).format('YYYY-MM-DD');
    }) : [];
    const data = {
      device: chosenDevice === '-1' ? '' : chosenDevice,
      dateFrom: dates[0] || '',
      dateTo: dates[1] || '',
    };

    dispatch(getQrReport(data, token))
  }

  return (
    <div className={'page__wrapper'}>
      <div className={'report__form-wrapper'}>
        {/* выбор терминала */}
        <div className={'report__form-block'}>
          <div className={'report__label'}>
            Терминал
          </div>
          <Select
            placeholder="Выберите терминал"
            onChange={onChangeTerminal}
            className={'report__fields'}
            value={chosenDevice}
          >
            {allDevices.map(item => (
              // @ts-ignore
              <Option
                key={item.id}
                value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
        {/* конец выбора терминала */}

        {/* блок для выбора дополнительных столбцов в таблице */}
        <Tooltip
          title={'Можно выбрать до трех дополнительных столбцов в таблицу отчетности '}>
          <div className={'report__label'}>
            Дополнительные (опциональные) поля
          </div>
        </Tooltip>
          <div className={'report__form-block report__column'}>
            {optionalCheckbox.map(item => {
              return (
                <>
                  <Checkbox
                    disabled={
                      !chosenDevice ||
                      (optionalColumns.length > 2 &&
                      (optionalColumns.findIndex((itemCol: any) => itemCol.name === item.name) === -1))
                    }
                    onChange={(e) => toggleOptionalColumns(e.target.checked, item)}>
                    {item.name}
                  </Checkbox>
                  <br />
                </>
              )
              })
            }
          </div>
        {/* конец блока для выбора дополнительных столбцов в таблице */}

        {/* блок для выбора всего периода дат */}
        <div className={'report__label'}>
          Период
        </div>
        <div className={'report__form-block'}>
          <Checkbox
            disabled={!chosenDevice}
            onChange={onChangeAllPeriod}>
            Весь период
          </Checkbox>
        </div>
        {/* конец блока для выбора всего периода дат */}

        {/*  выбор даты  */}
        <div className={'report__form-block report__date-fields'}>
          <Space direction="vertical" size={12} className={'report__date-fields'}>
            <RangePicker
              className={'report__date-fields'}
              onChange={onChangeDates}
              value={chosenDates}
              disabled={!chosenDevice || allPeriod }
            />
          </Space>
        </div>
        {/*  конец выбор даты  */}

        <ReportToExcel
          enabled={chosenDevice && (chosenDates || allPeriod)}
          chosenDates={chosenDates}
          allPeriod={allPeriod}
        />
      </div>

      <TablePaymentList optionalColumns={optionalColumns}/>
    </div>
  );
}

export default Report;
