import {
  GET_DEVICES_REQUEST,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAILED,
} from "../../../constants/variables";
import { IDevicesAction, IDevicesState } from "../../../types/state/devices";

const initialState: IDevicesState = {
  isLoadingDevices: false,
  devices: [],
  errorDevices: ''
};

const devicesReducer = (
  state=initialState,
    action: IDevicesAction
) => {
  switch (action.type) {
    case GET_DEVICES_REQUEST:
      return {
        ...state,
        isLoading: true,
        errorDevices: ''
      };

    case GET_DEVICES_SUCCESS:
      return {
        ...state,
        devices: action.payload,
        isLoading: false,
      };

    case GET_DEVICES_FAILED:
      return {
        ...state,
        devices: [],
        isLoading: false,
        errorDevices: 'Ошибка получения касс'
      };

    default:
      return state;
  }
};

export default devicesReducer;