import React, { useEffect } from 'react';
import TableTemplateList from "../../components/TableTemplateList";
import Modal from "../../components/Modal";
import { Button } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setModalData, toggleModal } from "../../redux/state/modal/action";
import {
  getTokenEvotorSelector,
  getTokenSelector
} from "../../redux/state/auth/selector";
import { getModalSelector } from "../../redux/state/modal/selector";
import { getTemplates } from "../../redux/state/templates/action";

const Home = () => {

  const dispatch = useDispatch();

  const modal = useSelector(getModalSelector);
  const token = useSelector(getTokenSelector);
  const evotorToken = useSelector(getTokenEvotorSelector);

  const setModal = (data: any) => {
    dispatch(toggleModal(true));
    dispatch(setModalData(data));
  };

  useEffect(() => {
    if (token) {
      dispatch(getTemplates(token));
    }
  }, [token]);

  return (
    <div className={'page__wrapper'}>

      <div>
        <Button
          type='primary'
          className={'button-size'}
          onClick={() => setModal({template: {}})}
        >
          Добавить
        </Button>

        <div className={'page__wrapper'}>
          <TableTemplateList />
        </div>
      </div>

      {
        modal ? <Modal /> : null
      }
    </div>
  )
};

export default Home;