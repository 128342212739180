import {
  GET_TEMPLATES_REQUEST,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAILED,
  ADD_TEMPLATES_REQUEST,
  ADD_TEMPLATES_SUCCESS,
  ADD_TEMPLATES_FAILED,
  EDIT_TEMPLATES_REQUEST,
  EDIT_TEMPLATES_SUCCESS,
  EDIT_TEMPLATES_FAILED,
  DELETE_TEMPLATES_REQUEST,
  DELETE_TEMPLATES_SUCCESS,
  DELETE_TEMPLATES_FAILED,
} from "../../../constants/variables";
import { reactBackendUrl } from "../../../constants/url";
import axios from "axios";
import { Dispatch } from "redux";
import { TTemplate } from "../../../types/state/templates";
import { toggleModal } from "../modal/action";

export const getTemplates = (token: string) => (dispatch: Dispatch) => {
  try {
    dispatch({
      type: GET_TEMPLATES_REQUEST,
    });

    const config = {
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }

    const response = axios.get(`${reactBackendUrl}/templates`, config);

    response.then((data) => {
      dispatch({
        type: GET_TEMPLATES_SUCCESS,
        payload: data.data.data
      })
    }).catch(() => {
      dispatch({
        type: GET_TEMPLATES_FAILED,
      })
    });
  } catch (err: any) {
    dispatch({
      type: GET_TEMPLATES_FAILED,
    });
  }
};

export const addTemplate = (data: TTemplate, token: string | undefined) => (dispatch: Dispatch) => {

  // эта переменная нужна нам для того, чтобы пробросить ее для установки флага
  // loading для одной конкретной рекламной кампании (детальнее описание в файле components/TableAdvList
  // функция onChange
  const isLoadingEnd = false;

  try {
    dispatch({
      type: ADD_TEMPLATES_REQUEST,
    });

    const config = {
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }

    const response = axios.post(`${reactBackendUrl}/templates`, data, config);

    response.then((data) => {
      dispatch({
        type: ADD_TEMPLATES_SUCCESS,
        payload: data.data.data
      });
      dispatch(toggleModal(false));
    }).catch(() => {
      dispatch({
        type: ADD_TEMPLATES_FAILED,
      })
    });

    return isLoadingEnd;

  } catch (err: any) {
    dispatch({
      type: ADD_TEMPLATES_FAILED,
    });
  }
};

export const editTemplate = (dataEdit: TTemplate, token: string | undefined) => (dispatch: Dispatch) => {

  // бэк не все парамсы принимает, а при выключении / выключении Шаблона из главной таблицы
  // у нас улетает весь объект. Уберем лишние поля, иначе валидация бэка не пропустит
  delete dataEdit.isLoading;
  delete dataEdit.userId;
  delete dataEdit.key;

  const isLoadingEnd = false;

  try {
    dispatch({
      type: EDIT_TEMPLATES_REQUEST,
    });

    const config = {
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }

    const response = axios.put(`${reactBackendUrl}/templates/${dataEdit.tplId}`, dataEdit, config);

    response.then((data) => {
      dispatch({
        type: EDIT_TEMPLATES_SUCCESS,
        payload: {
          editedTemplate: data.data.data,
          id: dataEdit.tplId
        }
      });
      dispatch(toggleModal(false));
    }).catch(() => {
      dispatch({
        type: EDIT_TEMPLATES_FAILED,
      })
    });

    return isLoadingEnd;

  } catch (err: any) {
    dispatch({
      type: EDIT_TEMPLATES_FAILED,
    });
  }
};

export const deleteTemplateAction = (id: string, token: string | undefined) => (dispatch: Dispatch) => {

  const isLoadingEnd = false;

  try {
    dispatch({
      type: DELETE_TEMPLATES_REQUEST,
    });

    const config = {
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    }

    const response = axios.delete(`${reactBackendUrl}/templates/${id}`, config);

    response.then((data) => {
      dispatch({
        type: DELETE_TEMPLATES_SUCCESS,
        payload: id
      });
      dispatch(toggleModal(false));
    }).catch(() => {
      dispatch({
        type: EDIT_TEMPLATES_FAILED,
      })
    });

    return isLoadingEnd;

  } catch (err: any) {
    dispatch({
      type: DELETE_TEMPLATES_FAILED,
    });
  }
};