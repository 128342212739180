import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./indexReducer";
import { routerMiddleware } from "connected-react-router";

const store = createStore(
  rootReducer(),
  applyMiddleware(
    logger,
    thunk,
    // routerMiddleware(),
  ),
);

export default store;
