export const SET_TOKEN = "SET_TOKEN";
export const SET_EVOTOR_TOKEN = "SET_EVOTOR_TOKEN";

export const CLEAR_ERROR_MES = "CLEAR_ERROR_MES";

export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAILED = 'GET_TEMPLATES_FAILED';

export const ADD_TEMPLATES_REQUEST = 'ADD_TEMPLATES_REQUEST';
export const ADD_TEMPLATES_SUCCESS = 'ADD_TEMPLATES_SUCCESS';
export const ADD_TEMPLATES_FAILED = 'ADD_TEMPLATES_FAILED';

export const EDIT_TEMPLATES_REQUEST = 'EDIT_TEMPLATES_REQUEST';
export const EDIT_TEMPLATES_SUCCESS = 'EDIT_TEMPLATES_SUCCESS';
export const EDIT_TEMPLATES_FAILED = 'EDIT_TEMPLATES_FAILED';

export const DELETE_TEMPLATES_REQUEST = 'DELETE_TEMPLATES_REQUEST';
export const DELETE_TEMPLATES_SUCCESS = 'DELETE_TEMPLATES_SUCCESS';
export const DELETE_TEMPLATES_FAILED = 'DELETE_TEMPLATES_FAILED';

export const GET_DEVICES_REQUEST = 'GET_DEVICES_REQUEST';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const GET_DEVICES_FAILED = 'GET_DEVICES_FAILED';

export const CLEAR_QR_REPORT = 'CLEAR_QR_REPORT';
export const GET_QR_REPORT_REQUEST = 'GET_QR_REPORT_REQUEST';
export const GET_QR_REPORT_SUCCESS = 'GET_QR_REPORT_SUCCESS';
export const GET_QR_REPORT_FAILED = 'GET_QR_REPORT_FAILED';

export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const SET_MODAL_DATA = "SET_MODAL_DATA";

export const optionalColumnsConst = [
  // из поля обязательных
  {
    name: 'Расчетный счет',
    title: 'PersonalAcc',
  },
  {
    name: 'Корреспондентский счет',
    title: 'CorrespAcc',
  },
  {
    name: 'БИК',
    title: 'BIC',
  },
  // из поля опциональных
  {
    name: 'Назначение',
    title: 'Purpose',
  },
  {
    name: 'Дата документа',
    title: 'DocDate',
  },
  {
    name: 'Фамилия Плательщика',
    title: 'LastName',
  },
  {
    name: 'Имя Плательщика',
    title: 'FirstName',
  },
  {
    name: 'Л/счет Плательщика',
    title: 'PersAcc',
  },
  {
    name: 'Адрес Плательщика',
    title: 'PayerAddress',
  },
  {
    name: 'Телефон Плательщика',
    title: 'Phone',
  },
  {
    name: 'Платежный период',
    title: 'PaymPeriod',
  },
  {
    name: 'Показание прибора учета',
    title: 'CounterVal',
  },
];
