import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/indexStore';
import moment from 'moment';
import 'moment/locale/ru';
import ruRU from 'antd/lib/locale/ru_RU';
import './styles/index.scss';
import App from './App';

moment.locale('ru');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider locale={ruRU}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);