import { combineReducers } from "redux";
import modalReducer from "./state/modal/reducer";
import authReducer from "./state/auth/redux";
import templateReducer from "./state/templates/redux";
import devicesReducer from "./state/devices/reducer";
import qrReportReducer from "./state/report/reducer";

export default function rootReducer() {
  return combineReducers({
    modal: modalReducer,
    auth: authReducer,
    templates: templateReducer,
    devices: devicesReducer,
    qrs: qrReportReducer,
  })
}
