import React from 'react';
import { Table, Tag, Space, Switch } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { setModalData, toggleModal } from "../../redux/state/modal/action";
import { editTemplate } from "../../redux/state/templates/action";
import { getTemplatesSelector } from "../../redux/state/templates/selector";
import { getTokenSelector } from "../../redux/state/auth/selector";

const TableTemplateList = () => {

  const dispatch = useDispatch();
  
  const token = useSelector(getTokenSelector);
  
  const templates = useSelector(getTemplatesSelector);
  
  const templatesData = templates && templates.map(item => {
    return {
      ...item,
      key: item.tplId
    }
  });

  const setModal = (data) => {
    dispatch(toggleModal(true));
    dispatch(setModalData(data));
  };

  const onChange = async (record) => {
    // свойства isLoading нет в объекте, его мы создаем сами, чтобы сделать лоадер
    // для включения / выключения конкретной рекламной кампании, ибо если это делать
    // через редакс, то сразу все кампании подпишутся на него и изменяя одну из них,
    // свойство loading будут ловить сразу все.
    // Также, это свойство пробрасываем из action, когда завершается логика запроса
    record.isLoading = true;
    const isLoadingEnd = await dispatch(editTemplate({...record, applied: !record.applied}, token));
    record.isLoading = isLoadingEnd;
  };

  const columns = [
    {
      title: 'Название',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Активация',
      align: 'center',
      width: '150px',
      key: 'applied',
      render: record => record.default ?
        null :
        <Switch
        checked={record.applied}
        loading={record?.isLoading}
        onChange={() => onChange(record)}
      />
    },
    {
      title: 'Условие применения шаблона',
      dataIndex: 'conditionKey',
      key: 'conditionKey',
    },
    {
      title: 'Текст чека',
      key: 'posName',
      dataIndex: 'posName',
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      width: '150px',
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined
            className={'pointer icons'}
            onClick={() => setModal({template: record})}
          />
          {record.default ?
          null : <DeleteOutlined
            className={'pointer icons'}
            onClick={() => setModal({template: record, deleting: true})}
            style={{
              color: '#ff4d4f'
            }}
          />}
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      dataSource={templatesData}
      scroll={{ y: 540 }}
    />
  )
};

export default TableTemplateList;