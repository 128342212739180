import { createSelector } from "reselect";
import { TState } from "../../../types/state";

// местный селектор, чтобы дернуть весь стейт по шаблонам
const getTemplateState = (state: TState) => state.templates;

export const getTemplateLoadingSelector = createSelector(
  getTemplateState,
  ({ isLoadingTemplate }) => isLoadingTemplate,
);

export const getTemplateChangeLoadingSelector = createSelector(
  getTemplateState,
  ({ isLoadingChangingTemplate }) => isLoadingChangingTemplate,
);

export const getTemplatesSelector = createSelector(
  getTemplateState,
  ({ templates }) => templates,
);

export const getTemplateGetErrorSelector = createSelector(
  getTemplateState,
  ({ errorGetTemplates }) => errorGetTemplates,
);

export const getTemplateChangeErrorSelector = createSelector(
  getTemplateState,
  ({ errorChangeTemplate }) => errorChangeTemplate,
);
