import {
  GET_TEMPLATES_REQUEST,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAILED,
  CLEAR_ERROR_MES,
  ADD_TEMPLATES_REQUEST,
  ADD_TEMPLATES_SUCCESS,
  ADD_TEMPLATES_FAILED,
  EDIT_TEMPLATES_REQUEST,
  EDIT_TEMPLATES_SUCCESS,
  EDIT_TEMPLATES_FAILED,
  DELETE_TEMPLATES_REQUEST,
  DELETE_TEMPLATES_SUCCESS,
  DELETE_TEMPLATES_FAILED
} from "../../../constants/variables";
import {
  ITemplateState,
  ITemplateAction
} from "../../../types/state/templates";

const initialState: ITemplateState = {
  isLoadingTemplate: false,
  isLoadingChangingTemplate: false,
  templates: [],
  errorGetTemplates: '',
  errorChangeTemplate: '',
}

const templateReducer = (
  state=initialState,
  action: ITemplateAction
): ITemplateState => {
  switch (action.type) {

    case CLEAR_ERROR_MES:
      return {
        ...state,
        errorGetTemplates: '',
        errorChangeTemplate: '',
      };

    case GET_TEMPLATES_REQUEST:
      return {
        ...state,
        isLoadingTemplate: true,
        errorGetTemplates: '',
      };

    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoadingTemplate: false,
        templates: action.payload,
      };

    case GET_TEMPLATES_FAILED:
      return {
        ...state,
        isLoadingTemplate: false,
        templates: [],
      };

    case ADD_TEMPLATES_REQUEST:
      return {
        ...state,
        isLoadingChangingTemplate: true,
        errorChangeTemplate: '',
      };

    case ADD_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoadingChangingTemplate: false,
        templates: [...state.templates, action.payload]
      };

    case ADD_TEMPLATES_FAILED:
      return {
        ...state,
        isLoadingChangingTemplate: false,
        errorChangeTemplate: 'Ошибка создания шаблона'
      };

    case EDIT_TEMPLATES_REQUEST:
      return {
        ...state,
        isLoadingChangingTemplate: true,
        errorChangeTemplate: ''
      };

    case EDIT_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoadingChangingTemplate: false,
        templates: state.templates.map(item => {
          if (item.tplId === action.payload.id) {
            item = action.payload.editedTemplate
          }
          return item
        })
      };

    case EDIT_TEMPLATES_FAILED:
      return {
        ...state,
        isLoadingChangingTemplate: false,
        errorChangeTemplate: 'Ошибка изменения шаблона'
      };

    case DELETE_TEMPLATES_REQUEST:
      return {
        ...state,
        isLoadingChangingTemplate: true,
        errorChangeTemplate: ''
      };

    case DELETE_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoadingChangingTemplate: false,
        templates: state.templates.filter(item => {
          return item.tplId !== action.payload
        })
      };

    case DELETE_TEMPLATES_FAILED:
      return {
        ...state,
        isLoadingChangingTemplate: false,
        errorChangeTemplate: 'Ошибка удаления шаблона'
      };

    default: return state
  }
}

export default templateReducer;