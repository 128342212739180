import {
  GET_QR_REPORT_REQUEST,
  GET_QR_REPORT_SUCCESS,
  GET_QR_REPORT_FAILED,
  CLEAR_QR_REPORT,
} from "../../../constants/variables";
import { IQrAction, IQrState } from "../../../types/state/qr";

const initialState: IQrState = {
  isLoadingQr: false,
  qrs: null,
  errorQr: ''
};

const qrReportReducer = (
  state=initialState,
  action: IQrAction
) => {
  switch (action.type) {
    case CLEAR_QR_REPORT:
      return initialState;

    case GET_QR_REPORT_REQUEST:
      return {
        ...state,
        isLoadingQr: true,
        errorQr: ''
      };

    case GET_QR_REPORT_SUCCESS:
      return {
        ...state,
        qrs: action.payload,
        isLoadingQr: false,
      };

    case GET_QR_REPORT_FAILED:
      return {
        ...state,
        qrs: null,
        isLoadingQr: false,
        errorQr: 'Ошибка получения отчета'
      };

    default:
      return state;
  }
};

export default qrReportReducer;