import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Form,
  Input,
  Checkbox,
  Tooltip,
} from 'antd';
import { toggleModal } from "../../../redux/state/modal/action";
import { useDispatch, useSelector } from "react-redux";
import {addTemplate, editTemplate} from "../../../redux/state/templates/action";
import {
  getTemplateChangeErrorSelector,
  getTemplateChangeLoadingSelector,
} from "../../../redux/state/templates/selector";
import { getTokenSelector } from "../../../redux/state/auth/selector";
import { CLEAR_ERROR_MES } from "../../../constants/variables";
import './index.scss';

const ModalTemplate = ({ dataTemp }: any) => {

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const isLoadingChangingTemplate = useSelector(getTemplateChangeLoadingSelector);
  const errorChangeTemplate = useSelector(getTemplateChangeErrorSelector);
  const token = useSelector(getTokenSelector);

  useEffect(() => {
    dispatch({
      type: CLEAR_ERROR_MES
    })
  })

  // applied - АКТИВАЦИЯ ШАБЛОНА
  const [active, setActive] = useState(dataTemp.template.applied || false);
  const toggleActive = () => setActive(!active);
  // КОНЕЦ applied

  const handleOk = (data: any) => {
    if (Object.keys(dataTemp.template).length) {
      data.tplId = dataTemp.template.tplId;
      data.default = dataTemp.template.default;
      dispatch(editTemplate(data, token))
    } else {
      dispatch(addTemplate(data, token))
    }
  }

  const handleCancel = () => {
    dispatch(toggleModal(false));
  }

  return (
    <Modal
      visible
      title={Object.keys(dataTemp.template).length ?
        'Изменить шаблон' : 'Добавить шаблон'}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button
          key="submit"
          htmlType="submit"
          form="advForm"
          type="primary"
          disabled={isLoadingChangingTemplate}
        >
          Сохранить
        </Button>,
        <Button
          key="back"
          // disabled={true}
          onClick={handleCancel}
        >
          Отмена
        </Button>
      ]}
      width={'700px'}
    >

      <Form
        form={form}
        layout={'vertical'}
        name="advForm"
        initialValues={{
          'applied': dataTemp.template.applied || false,
          'title': dataTemp.template.title || '',
          'conditionKey': dataTemp.template.conditionKey || '',
          'conditionValue': dataTemp.template.conditionValue || '',
          'posName': dataTemp.template.posName || '',
        }}
        onFinish={handleOk}>

        <Form.Item
          name="applied"
          valuePropName='checked'
        >
          <Checkbox
            onChange={toggleActive}>
            Активация
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="title"
          label="Название шаблона"
          rules={[
            {
              required: true,
              message: 'Укажите название',
            },
          ]}>
          <Input
            disabled={dataTemp.template.default}
            placeholder={'Название'}
            autoComplete={'off'}
          />
        </Form.Item>

        {/* ToDO: поставить условие, что если шаблон дефолтный, то убрать эти поля */}
        {dataTemp.template.default ?
          null :
          <Tooltip
            title={'Например: PersonalAcc = 0123456789'}>
            <div className={'modal__input-block-two-columns'}>
              <Form.Item
                name="conditionKey"
                label="Условие применения шаблона"
                className={'modal__input-block-two-columns_item'}
                rules={[
                  {
                    required: true,
                    message: 'Укажите условие',
                  },
                ]}>
                <Input
                  placeholder={'Условие'}
                  autoComplete={'off'}
                />
              </Form.Item>

              <div>
                =
              </div>

              <Form.Item
                name="conditionValue"
                label="Значение условия"
                className={'modal__input-block-two-columns_item'}
                rules={[
                  {
                    required: true,
                    message: 'Укажите значение',
                  },
                ]}>
                <Input
                  placeholder={'Значение'}
                  autoComplete={'off'}
                />
              </Form.Item>
            </div>
          </Tooltip>
        }

        <Tooltip
          title={'Например: Оплата услуг по документу №{DocNo} л/с {PersonalAcc} за период {PaymPerios}'}>
          <Form.Item
            name="posName"
            label="Текст чека"
            rules={[
              {
                required: true,
                message: 'Укажите текст',
              },
            ]}>
            <Input
              placeholder={'Значение'}
              autoComplete={'off'}
            />
          </Form.Item>
        </Tooltip>

        <p className={'error-mes'}>
          {errorChangeTemplate}
        </p>
      </Form>

    </Modal>
  )
};

export default ModalTemplate;