import {
  SET_TOKEN,
  SET_EVOTOR_TOKEN
} from "../../../constants/variables";

export const setToken = (token: string | undefined) => {
  return {
    type: SET_TOKEN,
    payload: token
  }
};

export const setEvotorToken = (evotorToken: string | undefined) => {
  return {
    type: SET_EVOTOR_TOKEN,
    payload: evotorToken
  }
};