import React from 'react';
import { Table } from 'antd';
import { useSelector } from "react-redux";
import {
  getQrReportErrorSelector,
  getQrReportLoadingSelector,
  getQrReportSelector
} from "../../redux/state/report/selector";
import moment from "moment";


const TablePaymentList = ({ optionalColumns }: any) => {

  const qrs = useSelector(getQrReportSelector);
  const isLoadingQr = useSelector(getQrReportLoadingSelector);
  const errorQr = useSelector(getQrReportErrorSelector);

  // у нас массив qrs приходит с объектами и вложенными в них объектом optional
  // чтобы избежать всякого гемора с отрисовкой таблицы,
  // вытащим объект optional наружу в корневой объект
  const parsedQrs = qrs && qrs.map(item => {
    const { optional, ...rest } = item;
    const result = {...rest, ...optional};
    return result
  });

  const qrsData = parsedQrs && parsedQrs.map(item => {
    return {
      ...item,
      key: item._id
    }
  });

  const localTime = new Date().getTimezoneOffset();

  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Наименование банка',
      dataIndex: 'BankName',
      key: 'BankName',
    },
    {
      title: 'Сумма',
      dataIndex: 'Sum',
      key: 'Sum',
      render: (Sum: string | number) => (+Sum / 100).toLocaleString('ru-RU') + ' руб.'
    },
    {
      title: 'Дата платежа',
      dataIndex: 'DateReceipt',
      key: 'DateReceipt',
      render: (DateReceipt: string) => {
        return moment(
          Date.parse(DateReceipt) + (localTime * 60 * 1000)).format('DD.MM.yyyy; LTS')
      }
    },
    ...optionalColumns.map((item: any) => {
      return {
        title: item?.name,
        dataIndex: item?.title,
        key: item?.title,
      }
    })
  ];

  return (
    <>
      {
        !qrs ?
        null :
        qrs.length ?
          // если загрузка идет при уже имеющемся отчете, то просто добавим opacity
          <Table
            className={isLoadingQr && qrs ? 'loading-opacity' : ''}
            columns={columns}
            pagination={false}
            dataSource={qrsData!}
            scroll={{ y: 540 }}
            expandable={{
              expandedRowRender: record => (
                <div>
                  <p className={'subtopic'}>Детали платежа: </p>
                  {record.Name ? <p>Наименование: {record.Name}</p> : null}
                  {record.BankName ? <p>Наименование банка: {record.BankName}</p> : null}
                  {record.PersonalAcc ? <p>Расчетный счет: {record.PersonalAcc}</p> : null}
                  {record.BIC ? <p>БИК: {record.BIC}</p> : null}
                  {record.CorrespAcc ? <p>Корреспондентский счет: {record.CorrespAcc}</p> : null}
                  {record.Sum ?
                    <p>
                      Сумма платежа: {(+record.Sum / 100).toLocaleString('ru-RU')} руб.
                    </p> :
                    null}
                  {record.DateReceipt ?
                    <p>
                      Дата и время платежа: {
                      moment(
                      Date.parse(record.DateReceipt) + (localTime * 60 * 1000)
                      ).format('DD.MM.yyyy; LTS')
                    }
                    </p> :
                    null}
                  {record?.Purpose ? <p>Назначение платежа: {record.Purpose}</p> : null}
                  {record?.DocDate ? <p>Дата документа: {record.DocDate}</p> : null}
                  {record?.LastName ? <p>Фамилия плательщика: {record.LastName}</p> : null}
                  {record?.FirstName ? <p>Имя плательщика: {record.FirstName}</p> : null}
                  {record?.PayerAddress ? <p>Адрес плательщика: {record.PayerAddress}</p> : null}
                  {record?.PersAcc ? <p>Лицевой счет: {record.PersAcc}</p> : null}
                  {record?.Flat ? <p>Квартира: {record.Flat}</p> : null}
                  {record?.Phone ? <p>Телефон плательщика: {record.Phone}</p> : null}
                  {record?.PaymPeriod ? <p>Платежный период: {record.PaymPeriod}</p> : null}
                  {record?.CounterVal ? <p>Показание прибора учета: {record.CounterVal}</p> : null}
                </div>
              ),
            }}
          /> :
          <p>
            По выбранным параметрам платежи отсутствуют
          </p>
      }

      {
        errorQr ?
          <p className={'error-mes'}>
            {errorQr}
          </p> : null
      }

      {/* эта загрузка будет показана, если отчет сформирован ранее не был */}
      {
        isLoadingQr && !qrs ?
          <p>
            Загрузка отчета...
          </p> : null
      }
    </>
  )
};

export default TablePaymentList;