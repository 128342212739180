import React, { useEffect }  from 'react';
import { Route, Routes } from "react-router-dom";
import queryString from "querystring";
import { Paths } from './constants/routes';
import { TNavigation } from "./types/routes";

import Home from "./pages/Home";
import Report from "./pages/Report";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  getTokenEvotorSelector,
  getTokenSelector
} from "./redux/state/auth/selector";
import {
  setEvotorToken,
  setToken
} from "./redux/state/auth/action";

export const navigation: TNavigation = [
  {
    name: 'Шаблоны',
    path: Paths.HOME,
    element: <Home />
  },
  {
    name: 'Отчет',
    path: Paths.REPORT,
    element: <Report />
  }
];

const App = () => {

  const dispatch = useDispatch();

  const token = useSelector(getTokenSelector);
  const evotorToken = useSelector(getTokenEvotorSelector);

  useEffect(() => {
    if (!token) {
      const newToken = queryString.parse(window.location.search)['?token'];
      // @ts-ignore
      dispatch(setToken(newToken));
    }
    if (!evotorToken) {
      const newEvotorToken = queryString.parse(window.location.search)['evotorToken'];
      // @ts-ignore
      dispatch(setEvotorToken(newEvotorToken));
    }
  }, [window.location]);

  const navigation: TNavigation = [
    {
      name: 'Шаблоны',
      path: Paths.HOME,
      element: <Home />
    },
    {
      name: 'Отчет',
      path: Paths.REPORT,
      element: <Report />
    }
  ];

  const pageItem = navigation.map(page => (
    <Route
      key={page.path}
      path={page.path}
      element={page.element}
    />
  ));

  return (
    <div className={'document__wrapper'}>
      <Header />
      <Routes>
        {pageItem}
      </Routes>
    </div>
  )
};

export default App;