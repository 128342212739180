import { createSelector } from "reselect";
import { TState } from "../../../types/state";

// местный селектор, чтобы дернуть весь стейт по авторизации
const getAuthState = (state: TState) => state.auth;

export const getTokenSelector = createSelector(
  getAuthState,
  ({ token }): string | undefined => token,
);

export const getTokenEvotorSelector = createSelector(
  getAuthState,
  ({ evotorToken }): string => evotorToken,
);

