import React from 'react';
import { Button } from "antd";
import '../../pages/Report/index.scss'
import {useSelector} from "react-redux";
import {
  getQrReportErrorSelector,
  getQrReportLoadingSelector,
  getQrReportSelector
} from "../../redux/state/report/selector";
import * as Excel  from 'exceljs';
import moment from "moment";
import { optionalColumnsConst } from "../../constants/variables";
import * as FileSaver from 'file-saver';
import {saveAs} from "file-saver";

type TReportToExcelProps = {
  enabled: boolean,
  allPeriod: boolean,
  chosenDates: string[]
}

const ReportToExcel: React.FC<TReportToExcelProps> = (
  {
    enabled,
    allPeriod,
    chosenDates,
  }) => {

  const qrs = useSelector(getQrReportSelector);
  const isLoadingQr = useSelector(getQrReportLoadingSelector);
  const errorQr = useSelector(getQrReportErrorSelector);

  const localTime = new Date().getTimezoneOffset();

  const staticColumns = [
    {
      name: 'Наименование',
      title: 'Name',
    },
    {
      name: 'Наименование банка',
      title: 'BankName',
    },
    {
      name: 'Сумма',
      title: 'Sum',
    },
    {
      name: 'Дата платежа',
      title: 'DateReceipt',
    },
  ];



  const allColumnsList = [...staticColumns, ...optionalColumnsConst];

  // у нас массив qrs приходит с объектами и вложенными в них объектом optional
  // чтобы избежать всякого гемора с отрисовкой таблицы,
  // вытащим объект optional наружу в корневой объект
  const parsedQrs = qrs && qrs.map(item => {
    const { optional, ...rest } = item;
    const result = {...rest, ...optional};
    result.DateReceipt = moment(
      Date.parse(result.DateReceipt) + (localTime * 60 * 1000)
    ).format('DD.MM.yyyy; LTS')
    result.Sum = (+result.Sum / 100).toLocaleString('ru-RU') + ' руб';
    return result
  });

  const createExcelReport = async () => {
    const workBook = new Excel.Workbook()
    const workSheet = workBook.addWorksheet('Отчет');

    function setExcelColumns() {
      const result = allColumnsList.map(item => {
        return {
          header: item.name,
          key: item.title,
          width: 26,
        }
      });

      return result
    }

    workSheet.columns = setExcelColumns();

    parsedQrs && parsedQrs.forEach(item => {
      workSheet.addRow(item)
    });

    workSheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
    });

    workSheet.properties.defaultRowHeight = 22;

      const buffer = await workBook.xlsx.writeBuffer();
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileExtension = '.xlsx';

      const blob = new Blob([buffer], {type: fileType});

      const dates = chosenDates?.length ? chosenDates.map((item: any) => {
        return moment(item).format('DD-MM-YYYY');
      }) : [];

      const partName = allPeriod ?
        'за весь период' :
        `за период с ${dates[0]} по ${dates[1]}`
      saveAs(blob, `Отчет ${partName}` + fileExtension);
  }

  return (
    <Button
      type="primary"
      disabled={!enabled || isLoadingQr || !!errorQr}
      className={'report__fields'}
      onClick={createExcelReport}
    >
      Скачать в .xlsx
    </Button>
  )
}

export default ReportToExcel;