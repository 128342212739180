import React from 'react';
import logo from '../../assets/images/logo_black.svg';
import { navigation } from "../../App";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getTokenEvotorSelector,
  getTokenSelector
} from "../../redux/state/auth/selector";
import './index.scss';

const Header = () => {
  const token = useSelector(getTokenSelector);
  const evotorToken = useSelector(getTokenEvotorSelector);

  // ToDO: проверить не будет ли добавляться лишний query параметр при депле в Эвотор
  const linkFullPath = (item: any) => {

    // это позволяет нам пробрасывать search параметр при переходе на новый роут
    const path = {
      pathname: item.path,
      search: `?token=${token}&evotorToken=${evotorToken}`
    };
    return path;
  }

  return (
    <div className={'header'}>
      <a
        href={'https://market.evotor.ru/store/publisher/8f12e73f-2872-4eb6-aa6e-5d1520a7cb51/apps'}
        target={'_blank'}
        aria-label={'link to developer page'}
        rel={'noreferrer'}
      >
        <img src={logo} alt={'logo'} className={'header__logo'} />
      </a>
      <ul className={'header__menu'}>
        {navigation.map(item => (
          <li
            key={item.path}

          >
            <NavLink
              to={linkFullPath(item)}
              className={'header__menu-item'}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
};

export default Header;