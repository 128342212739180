import { createSelector } from "reselect";
import { TState } from "../../../types/state";

// местный селектор, чтобы дернуть весь стейт по кассам
const getDevicesState = (state: TState) => state.devices;

export const getDevicesLoadingSelector = createSelector(
  getDevicesState,
  ({ isLoadingDevices }) => isLoadingDevices,
);

export const getDevicesSelector = createSelector(
  getDevicesState,
  ({ devices }) => devices,
);

export const getDevicesErrorSelector = createSelector(
  getDevicesState,
  ({ errorDevices }) => errorDevices,
);
