import {
  TOGGLE_MODAL,
  SET_MODAL_DATA
} from "../../../constants/variables";
import {
  IModalAction,
  IModalState
} from "../../../types/state/modal";

const initialState: IModalState = {
  modal: false,
  modalData: null
};

export default function modalReducer(
  state = initialState,
  action: IModalAction
): IModalState {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        modal: action.payload
      };
    case SET_MODAL_DATA:
      return {
        ...state,
        modalData: action.payload
      };

    default: return state;
  }
}