import {
  SET_TOKEN,
  SET_EVOTOR_TOKEN,
} from "../../../constants/variables";
import {IAuthAction, IAuthState} from "../../../types/state/auth";

const initialState: IAuthState = {
  token: '',
  evotorToken: '',
};

const authReducer = (
  state = initialState,
  action: IAuthAction): IAuthState => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case SET_EVOTOR_TOKEN:
      return {
        ...state,
        evotorToken: action.payload,
      };

    default: return state
  }
}

export default authReducer;