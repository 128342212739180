import React, { useEffect } from 'react';
import { Modal, Button } from 'antd';
import { toggleModal } from "../../../redux/state/modal/action";
import { useDispatch, useSelector } from "react-redux";
import { deleteTemplateAction } from "../../../redux/state/templates/action";
import { CLEAR_ERROR_MES } from "../../../constants/variables";
import { getTemplateChangeErrorSelector } from "../../../redux/state/templates/selector";
import {getTokenSelector} from "../../../redux/state/auth/selector";

const ModalDeleting = ({ dataTemp }: any) => {

  const errorTemplate = useSelector(getTemplateChangeErrorSelector);
  const token = useSelector(getTokenSelector);

  const dispatch = useDispatch();

  const handleOk = () => {
    if (dataTemp.template) {
      dispatch(deleteTemplateAction(dataTemp.template.tplId, token))
    }
  }

  useEffect(() => {
    dispatch({
      type: CLEAR_ERROR_MES
    })
  }, []);

  const handleCancel = () => {
    dispatch(toggleModal(false));
  }

  return (
    <Modal
      visible
      title={'Подтвердите удаление'}
      onOk={handleOk}
      onCancel={handleCancel}
      bodyStyle={errorTemplate ? {} : {display: 'none'}}
      footer={[
        <Button
          key="submit"
          // disabled={true}
          type="primary"
          danger
          onClick={handleOk}
        >
          Удалить
        </Button>,
        <Button
          key="back"
          // disabled={true}
          onClick={handleCancel}
        >
          Отмена
        </Button>
      ]}
    >
      <p className={'error_mes'}>
        {errorTemplate}
      </p>
    </Modal>
  )
};

export default ModalDeleting;