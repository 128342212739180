import { createSelector } from "reselect";
import { TState } from "../../../types/state";

// местный селектор, чтобы дернуть весь стейт по платежкам
const getQrReportState = (state: TState) => state.qrs;

export const getQrReportLoadingSelector = createSelector(
  getQrReportState,
  ({ isLoadingQr }) => isLoadingQr,
);

export const getQrReportSelector = createSelector(
  getQrReportState,
  ({ qrs }) => qrs,
);

export const getQrReportErrorSelector = createSelector(
  getQrReportState,
  ({ errorQr }) => errorQr,
);

