import React from 'react';
import { useSelector } from "react-redux";
import ModalTemplate from "./ModalTemplate";
import ModalDeleting from "./ModalDeleting";
import { getModalDataSelector } from "../../redux/state/modal/selector";

const Modal = () => {

  const modalData = useSelector(getModalDataSelector);

  return (
    <>
      {
        modalData.template && !modalData.deleting ?
          <ModalTemplate dataTemp={modalData}/> : null
      }

      {
        modalData.deleting ?
          <ModalDeleting dataTemp={modalData} /> : null
      }
    </>
  )
};

export default Modal;