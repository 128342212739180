import {
  GET_DEVICES_REQUEST,
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAILED,
} from "../../../constants/variables";
import { evotorBackendUrl } from "../../../constants/url";
import { Dispatch } from "redux";
import axios from "axios";

export const getDevices = (evotorToken: string) => (dispatch: Dispatch) => {
  try {
    dispatch({
      type: GET_DEVICES_REQUEST,
    });

    const config = {
      headers: {
        'X-Authorization': evotorToken,
        'Content-Type': 'application/vnd.evotor.v2+json'
      }
    }

    const response = axios.get(`${evotorBackendUrl}/devices`, config);

    response.then((data) => {
      dispatch({
        type: GET_DEVICES_SUCCESS,
        payload: data.data.items
      })
    }).catch(() => {
      dispatch({
        type: GET_DEVICES_FAILED,
      });
    });

  } catch (err) {
    dispatch({
      type: GET_DEVICES_FAILED,
    });
  }
}