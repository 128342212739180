import {
  TOGGLE_MODAL,
  SET_MODAL_DATA
} from '../../../constants/variables';

export const toggleModal = (bool: boolean) => {
  return {
    type: TOGGLE_MODAL,
    payload: bool
  }
}

export const setModalData = (data: any) => {
  return {
    type: SET_MODAL_DATA,
    payload: data
  }
}